import React from "react";

const CategoryModal = ({
    show,
    handleCookie,
    category,
    handleClose
}) => {
    return (
        <div style={{
            display: show ? 'block' : 'none',
            position: 'fixed',
            zIndex: '1',
            right: '0',
            top: '0',
            backgroundColor: 'white',
            overflow: 'auto',
            boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)',
            padding: '10px',
            borderRadius: '10px',
            width: '200px',
            margin: "10px"
        }}>
            <div className=" text-center">
                <p>{`Do you want to set your preference to default to seeing ${category} content?`}</p>
                <div className="d-flex justify-content-around">
                    <button className="btn btn-primary mr-2" onClick={handleCookie}>Yes</button>
                    <button className="btn btn-secondary" onClick={handleClose}>No</button>
                </div>
            </div>
        </div>
    )
}

export default CategoryModal